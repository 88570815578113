import React from 'react';
import Loadable from 'react-loadable';
import SectionUsage from '../partials/_SectionUsage';
import SectionPlatformsDesign from '../partials/_SectionPlatforms';
import SectionPageNav from '../partials/_SectionPageNav';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  ComponentWithLabel,
  PageHero,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from '../../../../components';
import V5Notice from '../../v5-notice/V5Notice';
import avatarPreviewData from '../../../../data/previews/avatar.yml';
import pageHeroData from '../../../../data/pages/components.yml';

const AvatarTeam = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.AvatarTeam;
    return <Component {...props} />;
  },
});

const IndexPage = () => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Team Avatar"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Avatars" tierThree="Team" />
      <V5Notice
        componentName="Avatars"
        storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-avatars--docs"
      />

      <SectionPageNav />

      <Section title="Style">
        <Paragraph>
          The avatar’s style depends on the content available.
        </Paragraph>
        <ComponentPreview
          name="AvatarStyle"
          previewData={avatarPreviewData.teamTypes}>
          <AvatarTeam size="xlarge" />
        </ComponentPreview>
      </Section>

      <Section title="Sizes">
        <Paragraph>
          Consider layout density and view hierarchy when choosing an avatar’s
          size.
        </Paragraph>
        <ComponentPreview
          name="AvatarSize"
          layout="split"
          previewData={avatarPreviewData.sizes}>
          <AvatarTeam size="xsmall" />
        </ComponentPreview>

        <SectionSubhead>Custom Sizes</SectionSubhead>
        <Paragraph>
          There are some cases when an additional size may be necessary and a
          custom size can be used. The only custom size currently available (for
          arguably the biggest use case) is profile.
        </Paragraph>
        <ComponentPreview name="CustomSize">
          <ComponentWithLabel labelText="Profile">
            <AvatarTeam size="profile" />
          </ComponentWithLabel>
        </ComponentPreview>
      </Section>

      <SectionUsage />

      <AccessibilityAuditComponentResultsSection componentName="Avatars" />

      <SectionPlatformsDesign />
    </PageWithSubNav>
  );
};

export default IndexPage;
